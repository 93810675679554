import React from 'react'
import './Side.css' 
import RecChanges from '../etc/RecChanges';
import Chart from "../etc/Chart"
import Discuss from "../etc/Discuss"

const Side = () => {
  return (
    <div>
      <section>
        <Chart />
        <Discuss  isSidebar={true} />
        <RecChanges isSidebar={true} />
      </section>
    </div>
  )
}

export default Side
