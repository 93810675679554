import React, { useState } from 'react';
import axios from 'axios';
import './MyPage.css';

const MyPage = ({ user, setUser }) => {
  const [name, setName] = useState(user?.name || '');
  const [email, setEmail] = useState(user?.email || '');
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const API_URL = import.meta.env.VITE_API_URL; // 환경 변수에서 API URL 가져오기

  const handleNameChange = async () => {
    if (!name.trim()) {
      setErrorMessage('이름을 입력해주세요.');
      return;
    }

    try {
      const response = await axios.put(`${API_URL}/users/update-name`, {
        credentials: 'include',
        id: user.id,
        name,
      });

      if (response.data.success) {
        alert('이름이 변경되었습니다.');
        setUser((prevUser) => ({ ...prevUser, name }));
        setIsEditingName(false);
      } else {
        setErrorMessage('이름 변경에 실패했습니다.');
      }
    } catch (error) {
      console.error('이름 변경 오류:', error);
      setErrorMessage(
        error.response?.data?.message || '서버 오류로 인해 이름을 변경할 수 없습니다.'
      );
    }
  };

  const handleEmailChange = async () => {
    if (!email.trim()) {
      setErrorMessage('이메일을 입력해주세요.');
      return;
    }

    try {
      const response = await axios.put(`${API_URL}/users/update-email`, {
        credentials: 'include',
        id: user.id,
        email,
      });

      if (response.data.success) {
        alert('이메일이 변경되었습니다.');
        setUser((prevUser) => ({ ...prevUser, email }));
        setIsEditingEmail(false);
      } else {
        setErrorMessage('이메일 변경에 실패했습니다.');
      }
    } catch (error) {
      console.error('이메일 변경 오류:', error);
      setErrorMessage(
        error.response?.data?.message || '서버 오류로 인해 이메일을 변경할 수 없습니다.'
      );
    }
  };

  const handlePasswordChange = () => {
    alert('비밀번호 변경 페이지로 이동합니다.');
    // 페이지 이동 또는 팝업 처리
  };

  const handleMobileAuth = async () => {
    try {
      const response = await axios.post(`${API_URL}/users/mobile-auth`, {
        credentials: 'include',
        id: user.id,
      });

      if (response.data.success) {
        alert('모바일 인증이 완료되었습니다.');
      } else {
        setErrorMessage('모바일 인증에 실패했습니다.');
      }
    } catch (error) {
      console.error('모바일 인증 오류:', error);
      setErrorMessage(
        error.response?.data?.message || '서버 오류로 인해 모바일 인증을 완료할 수 없습니다.'
      );
    }
  };

  const handleAccountDelete = async () => {
    const confirmed = window.confirm('정말로 계정을 삭제하시겠습니까?');
    if (confirmed && user?.id) {
        try {
            const response = await axios.delete(`${API_URL}/users/delete-account`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                data: { id: user.id }, // 사용자 ID를 요청 본문에 포함
                withCredentials: true, // 인증 정보를 포함
            });

            if (response.status === 200) {
                alert('계정이 삭제되었습니다.');
                setUser(null); // 사용자 상태 초기화
                window.location.href = '/'; // 홈 페이지로 리다이렉트
            } else {
                setErrorMessage('계정 삭제에 실패했습니다. 다시 시도해주세요.');
            }
        } catch (error) {
            console.error('계정 삭제 중 오류:', error);
            setErrorMessage(
                error.response?.data?.message || '서버 오류로 인해 계정을 삭제할 수 없습니다.'
            );
        }
    }
};

  return (
    <div className="myPage">
      <h1>내 정보</h1>
      <div className="infoSection">
        <label>사용자 이름</label>
        {isEditingName ? (
          <div className="editSection">
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <button onClick={handleNameChange}>변경</button>
          </div>
        ) : (
          <div>
            <span>{name}</span>
            <button onClick={() => setIsEditingName(true)}>이름 변경</button>
          </div>
        )}
      </div>

      <div className="infoSection">
        <label>이메일</label>
        {isEditingEmail ? (
          <div className="editSection">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button onClick={handleEmailChange}>변경</button>
          </div>
        ) : (
          <div>
            <span>{email}</span>
            <button onClick={() => setIsEditingEmail(true)}>이메일 변경</button>
          </div>
        )}
      </div>

      <div className="infoSection">
        <label>권한</label>
        <span>{user?.isAdmin ? 'Admin' : 'Member'}</span>
      </div>

      <div className="infoSection">
        <label>비밀번호</label>
        <button onClick={handlePasswordChange}>비밀번호 변경</button>
      </div>

      <div className="infoSection">
        <label>모바일 인증</label>
        <button onClick={handleMobileAuth}>인증</button>
      </div>

      <div className="actions">
        <button className="deleteAccount" onClick={handleAccountDelete}>
          계정 삭제
        </button>
      </div>

      {errorMessage && (
        <p style={{ color: 'red', marginTop: '10px' }}>{errorMessage}</p>
      )}
    </div>
  );
};

export default MyPage;
