import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './Components/Navbar/Navbar';
import Body from './Components/Body/Body';
import Side from './Components/Body/Side';

import Register from './Components/Body/Register';
import Login from './Components/Body/Login';

import MyPage from './Components/Body/MyPage';
import MyDocument from './Components/Body/MyDocument';

import Discuss from './Components/etc/Discuss';
import CreateDiscussion from './Components/etc/CreateDiscussion';
import DiscussionDetail from './Components/etc/DiscussionDetail';
import RecChange from './Components/etc/RecChanges';

import SearchResults from './Components/Body/searchResults';
import CreateContent from './Components/Body/CreateContent';

import { useCookies } from 'react-cookie';
import axios from 'axios';
import { UserContext } from './Components/UserContext';

import './App.css';

const App = () => {
  const [user, setUser] = React.useState(null);
  const [cookies, , removeCookie] = useCookies(['userToken']);
  const [discussions, setDiscussions] = useState([]); // Discuss 컴포넌트 상태 관리

  const API_URL = import.meta.env.VITE_API_URL;

  // 조회수 갱신된 토론 목록 상태 갱신
  const updateDiscussion = (updatedDiscussion) => {
    setDiscussions((prevDiscussions) =>
      prevDiscussions.map((discussion) =>
        discussion.id === updatedDiscussion.id ? updatedDiscussion : discussion
      )
    );
  };

  useEffect(() => {
    // API 연결 확인
    console.log('API_URL:', API_URL); // API URL 값 확인
    axios
      .get(`${API_URL}/status`, {
        credentials: 'include',
      })
      .then((response) => {
        console.log('프론트엔드와 백엔드 API가 연결되었습니다:', response.data);
      })
      .catch((error) => {
        console.error('백엔드 API 연결에 실패했습니다:', error);
      });

    // 기존 토큰으로 사용자 정보 확인
    const token = cookies.userToken;
    if (token && !user) {
      axios
        .get(`${API_URL}/users/profile`, {
          credentials: 'include',
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setUser(response.data.user);
        })
        .catch(() => {
          removeCookie('userToken', { path: '/' });
        });
    }
  }, [API_URL, cookies, user, removeCookie]);

  const ProtectedRoute = ({ children }) => {
    if (!user) {
      return <Navigate to="/login" replace />;
    }
    return children;
  };

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <div className="nav">
        <Navbar user={user} setUser={setUser} />
      </div>
      <div className="content">
        <div className="body-side-container">
          <div className="body">
            <Routes>
              <Route path="/" element={<Navigate to="/content/1" replace />} />
              <Route path="/createContent" element={<CreateContent />} />
              <Route path="/content/:id" element={<Body user={user} />} />
              <Route path="/search" element={<SearchResults />} />
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login setUser={setUser} />} />
              <Route
                path="/myPage"
                element={
                  <ProtectedRoute>
                    <MyPage user={user} setUser={setUser} />
                  </ProtectedRoute>
                }
              />
              <Route path="/myDocument" element={<MyDocument user={user} />} />
              <Route
                path="/discuss"
                element={<Discuss user={user} discussions={discussions} />}
              />
              <Route path="/create" element={<CreateDiscussion />} />
              <Route
                path="/discuss/:id"
                element={
                  <ProtectedRoute>
                    <DiscussionDetail user={user} />
                  </ProtectedRoute>
                }
              />
              <Route path="/recChanges" element={<RecChange user={user} />} />
            </Routes>
            <div className="footer-container">
          <h2>개인정보 처리방침</h2>
          <p>본 사이트는 사용자의 개인정보를 중요시하며, 관련 법령을 준수합니다.</p>
          <p>
            수집 항목: 이름, 이메일, 비밀번호 |
            수집 목적: 서비스 제공 및 개선 <br />
            보유 기간: 회원 탈퇴 시까지 또는 관련 법령에 따라 보관 |
            권리: 사용자는 정보 열람, 수정, 삭제를 요청할 수 있습니다.
          </p>
          <p>문의: klglpene@naver.com </p>
          </div>
          </div>
          <div className="side">
            <Side />
          </div>
        </div>
      </div>
      
    </UserContext.Provider>
  );
};

export default App;
