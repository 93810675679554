import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './RecChanges.css';
import clock from '../../assets/clock.png';

const RecChanges = ({ isSidebar, user }) => {
  const [recentChanges, setRecentChanges] = useState([]);
  const [error, setError] = useState(null);

  const API_URL = import.meta.env.VITE_API_URL; // 환경 변수에서 API URL 가져오기

  // 서버에서 최근 변경된 콘텐츠 가져오기
  const fetchRecentChanges = () => {
    const limit = isSidebar ? 5 : 10;
    const excludeDocumentId = user?.myDocument || '';

    fetch(`${API_URL}/api/content/recent?limit=${limit}&excludeDocumentId=${excludeDocumentId}`, {
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setRecentChanges(data);
        setError(null); // 에러 초기화
      })
      .catch((error) => {
        console.error('Error fetching recent changes:', error);
        setError('최근 변경 내역을 가져오는 중 오류가 발생했습니다.');
      });
  };

  useEffect(() => {
    fetchRecentChanges();
    const interval = setInterval(fetchRecentChanges, 30000); // 30초마다 호출

    return () => clearInterval(interval);
  }, [isSidebar, user, API_URL]);

  return (
    <div className={`rec-changes-container ${isSidebar ? 'recChanges-sidebar-view' : 'full-view'}`}>
      {error ? (
        <p className="error-message">{error}</p>
      ) : recentChanges.length === 0 ? (
        <p className="contents">최근 변경된 항목이 없습니다.</p>
      ) : isSidebar ? (
        // isSidebar가 true일 때는 문서명과 수정시간만 보여주기
        <table className="recentChangesList">
          <div>
            <li>
              <img src={clock} alt="clock" />
            </li>
            <li>
              <span> 최근 변경 </span>
            </li>
          </div>
          {recentChanges.map((change) => (
            <table key={change.id} className="recentChangeItem">
              <tbody>
                <tr>
                  <td>
                    <Link to={`/content/${change.id}`}>{change.title}</Link>
                  </td>
                  <td>
                    <span className="lastModified">
                      {new Date(change.lastModified).toLocaleString('ko-KR')}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          ))}
        </table>
      ) : (
        // isSidebar가 false일 때는 전체 테이블 정보 보여주기
        <div className="boxContent">
          <div className="boxtitle">
            <div className="boxTitles">
              <label>
                <h1>최근 변경내역</h1>
              </label>
            </div>
          </div>
          <table className="recentChangesTable">
            <thead>
              <tr>
                <th>문서 제목</th>
                <th>기능</th>
                <th>작성자</th>
                <th>마지막 수정 시간</th>
              </tr>
            </thead>
            <tbody>
              {recentChanges.map((change) => (
                <tr key={change.id} className="recentChangeItem">
                  <td>
                    <Link to={`/content/${change.id}`}>{change.title}</Link>
                  </td>
                  <td>미구현</td>
                  <td>{change.editor || '알 수 없음'}</td>
                  <td>{new Date(change.lastModified).toLocaleString('ko-KR')}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default RecChanges;
