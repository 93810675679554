import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import DOMPurify from 'dompurify'; // HTML 파싱 보안 추가
import './Body.css';

import starEmpty from '../../assets/star-empty.png';
import starFilled from '../../assets/star-filled.png';
import recent_edited from '../../assets/recent_edited.png';
import discuss from '../../assets/discuss.png';
import locker from '../../assets/locker.png';

const Body = ({ user }) => {
  const { id } = useParams(); // URL에서 콘텐츠 ID 가져오기
  const contentId = id || 1; // id가 없을 경우 기본값 설정
  const API_URL = import.meta.env.VITE_API_URL; // 환경 변수에서 API URL 가져오기

  const [content, setContent] = useState(null);
  const [isEditing, setIsEditing] = useState(false); // 수정 모드 상태
  const [isStarred, setIsStarred] = useState(false); // 즐겨찾기 상태
  const [editedContent, setEditedContent] = useState({
    title: '',
    category: '',
    content: '',
    advertisement: '', // 광고 이미지 필드 추가
  });

  // 콘텐츠 데이터 가져오기
  useEffect(() => {
    fetch(`${API_URL}/api/content/${contentId}`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setContent(data);
        setEditedContent({
          title: data.title,
          category: data.category,
          content: data.content,
          advertisement: data.advertisement,
        });

        // 즐겨찾기 여부 확인
        if (user && user.starredId) {
          const isAlreadyStarred = user.starredId.includes(contentId);
          setIsStarred(isAlreadyStarred);
        }
      })
      .catch((error) => {
        console.error('Error fetching content:', error);
      });
  }, [API_URL, contentId, user]);

  // 문서 수정 후 저장
  const handleEditSave = () => {
    if (!user) {
      alert('로그인 후 저장이 가능합니다.');
      return;
    }

    const updatedContent = {
      ...editedContent,
      editor: user.name, // 현재 로그인한 사용자를 수정자로 설정
    };

    fetch(`${API_URL}/api/content/${contentId}`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedContent),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        alert(data.message);
        setContent(updatedContent);
        setIsEditing(false);
      })
      .catch((error) => {
        console.error('Error saving content:', error);
        alert('저장 중 오류가 발생했습니다.');
      });
  };

  // 즐겨찾기 클릭 처리
  const handleStarClick = () => {
    if (!user) {
      alert('로그인 후 즐겨찾기 가능합니다.');
      return;
    }

    const endpoint = isStarred
      ? `${API_URL}/api/content/${contentId}/unstar`
      : `${API_URL}/api/content/${contentId}/star`;

    fetch(endpoint, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then(() => {
        setIsStarred(!isStarred);
      })
      .catch((error) => {
        console.error('Error updating starred:', error);
      });
  };

  const parseHTMLToJSX = (htmlString) => {
    // DOMPurify로 허용된 태그만 정제
    const sanitizedHTML = DOMPurify.sanitize(htmlString, {
      ALLOWED_TAGS: ['b', 'i', 's', 'h1', 'p', 'li', 'hr', 'br'],
    });
  
    // 문자열을 줄 단위로 나누어 JSX로 변환
    return sanitizedHTML.split('\n').map((line, index) => {
      if (line.startsWith('<h1>')) {
        return <h1 key={index}>{line.replace(/<\/?h1>/g, '')}</h1>;
      } else if (line.startsWith('<p>')) {
        return <p key={index}>{line.replace(/<\/?p>/g, '')}</p>;
      } else if (line.startsWith('<li>')) {
        return <li key={index}>{line.replace(/<\/?li>/g, '')}</li>;
      } else if (line.startsWith('<hr>')) {
        return <hr key={index} />;
      } else if (line.startsWith('<b>')) {
        return <b key={index}>{line.replace(/<\/?b>/g, '')}</b>;
      } else if (line.startsWith('<i>')) {
        return <i key={index}>{line.replace(/<\/?i>/g, '')}</i>;
      } else if (line.startsWith('<s>')) {
        return <s key={index}>{line.replace(/<\/?s>/g, '')}</s>;
      } else if (line.startsWith('<br>')) {
        return <br key={index} />;
      } else {
        // 그 외의 태그는 일반 텍스트로 처리
        return (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        );
      }
    });
  };
  

  return (
    <div className="mainContent">
      <div className="firstContent">
        <div className="znmqjwsnz">
          {isEditing ? (
            <label>
              <h2>
                제목:
                <input
                  type="text"
                  name="title"
                  value={editedContent.title}
                  onChange={(e) => setEditedContent({ ...editedContent, title: e.target.value })}
                />
              </h2>
            </label>
          ) : (
            <Link to={`/content/${contentId}`}>
              <h1>{content?.title}</h1>
            </Link>
          )}
          <div className="recentEdit">
            최근 수정 시각:
            <time>
              {content && new Date(content.lastModified).toLocaleString('ko-KR', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                  })}
              </time>
          </div>
        </div>
        <div className="buttonFunctions">
          <div>
            <span className="starredNum">{content?.starred}</span>
          </div>
          <li className="btnBox01">
            <a href="#" onClick={(e) => e.preventDefault() || handleStarClick()}>
              <img src={isStarred ? starFilled : starEmpty} alt="Star" />
            </a>
          </li>
          <li>
            {user && user.isAdmin ? (
              <li className="box02">
                <a href="#" onClick={(e) => { e.preventDefault(); setIsEditing(true); }}>
                  <img src={recent_edited} alt="Edit" />
                  <span>편집</span>
                </a>
              </li>
            ) : (
              <li className="btnBox02">
                <a href="#" onClick={(e) => { e.preventDefault(); alert('편집 권한이 부족합니다!'); }} className="lockedButton">
                  <img src={recent_edited} alt="Edit" />
                  <span>편집</span>
                  <img src={locker} alt="Locked" className="lockerIcon" />
                </a>
              </li>
            )}
            <li className="btnBox03">
              <a href="#discuss">
                <img src={discuss} alt="Discuss" />
                <span>토론</span>
              </a>
            </li>
          </li>
        </div>
      </div>
      <hr />
      <div className="secondContent">
        <img
          className="contentImg"
          src={`../../Images/${editedContent.advertisement}`}
          alt="Advertisement"
        />
      </div>
      <div className="thirdContent">
        {isEditing ? (
          <div className="editForm">
            <label>
              카테고리:
              <input
                type="text"
                name="category"
                value={editedContent.category}
                onChange={(e) => setEditedContent({ ...editedContent, category: e.target.value })}
              />
            </label>
            <hr />
            <label>
              내용:
              <textarea
                name="content"
                value={editedContent.content}
                onChange={(e) => setEditedContent({ ...editedContent, content: e.target.value })}
                rows="15"
              />
            </label>
            <button onClick={handleEditSave}>저장하기</button>
            <button onClick={() => setIsEditing(false)}>취소</button>
          </div>
        ) : (
          <article>
            <li className="info-category">
              <span> 분류: </span>
              <a href="/">{content?.category}</a>
            </li>
            <hr />
            <div>{content?.content && parseHTMLToJSX(content.content)}</div>
          </article>
        )}
      </div>
      <hr />
      <div className="fourthContent">
        <span>
          대림위키는 백과사전이 아니며 편향적이거나 잘못 서술되어 있을 수 있습니다.
          <br />
          대림위키는 응애위키입니다. 여러분이 직접 문서를 고칠 수 있으며, 다른 사람의 의견을
          원할 경우 직접 토론을 발제할 수 있습니다.
        </span>
      </div>
    </div>
  );
};

export default Body;
