import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Register.css';

const Register = () => {
    const [step, setStep] = useState(1); // 현재 단계 상태
    const [email, setEmail] = useState('');
    const [formData, setFormData] = useState({ name: '', password: '', confirmPassword: '' });
    const [isAgreed, setIsAgreed] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate(); // useNavigate를 사용해 페이지 이동 처리

    const API_URL = import.meta.env.VITE_API_URL; // 환경 변수에서 API URL 가져오기

    // 이메일 형식 검증 (daum.net, naver.com, gmail.com 도메인만 허용)
    const emailRegex = /^[a-zA-Z0-9._%+-]+@(daum\.net|naver\.com|gmail\.com)$/;
    const passwordRegex = /^(?=.*[!@#$%^&*()]).{8,}$/;
    
    // Step 1에서 "확인" 버튼을 누르면 Step 2로 이동
    const handleEmailSubmit = async (e) => {
        e.preventDefault();
    
        if (!isAgreed) {
            setError('약관에 동의해야 가입이 가능합니다.');
            return;
        }
    
        if (!emailRegex.test(email)) {
            setError('이메일 형식이 올바르지 않거나 허용되지 않는 도메인입니다.');
            return;
        }
    
        try {
            // 이메일 중복 확인 API 호출
            const response = await axios.post(`${API_URL}/users/check-email`, { email });
    
            if (response.status === 200) {
                // 이메일 중복 없음 → Step 2로 이동
                const name = email.split('@')[0]; // 이메일에서 사용자 이름 추출
                setFormData((prev) => ({ ...prev, name }));
                setError('');
                setStep(2);
            }
        } catch (err) {
            if (err.response && err.response.status === 409) {
                setError(err.response.data.message); // 이미 등록된 이메일
            } else {
                setError('서버 오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
            }
        }
    };    

    // Step 2에서 회원가입 처리
    const handleRegisterSubmit = async (e) => {
      e.preventDefault();
  
      const { password, confirmPassword } = formData;
  
      if (!passwordRegex.test(password)) {
          setError('비밀번호는 8자리 이상이며, 특수문자를 포함해야 합니다.');
          return;
      }
  
      if (password !== confirmPassword) {
          setError('비밀번호가 일치하지 않습니다.');
          return;
      }
  
      try {
          // API 요청
          const response = await axios.post(`${API_URL}/users/register`, {
              email,
              password,
          });
  
          console.log('회원가입 응답:', response.data); // 응답 확인
          alert('회원가입 완료!');
          navigate('/login');
          
      } catch (err) {
          console.error('회원가입 요청 오류:', err.response?.data || err.message);
          setError(err.response?.data?.message || '서버와 통신 중 문제가 발생했습니다.');
      }
  };

    // 입력값 변경 처리
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    return (
        <div className="register-content">
            {step === 1 && (
                <form onSubmit={handleEmailSubmit}>
                    <h2>계정 만들기</h2>
                    <textarea
                        className="agreeInform"
                        cols="30"
                        rows="5"
                        readOnly
                        value={`본 사이트는, 대림대학교 2024년도 2학기 응용SW 학과 기말고사용 사이트를 목적으로 개설되었습니다. 사용자의 신상정보는 철저히 암호화되어 보호될 예정입니다.

[개인정보 수집 및 이용 동의서]

1. 수집하는 개인정보 항목
- 필수항목: 이름, 이메일, 비밀번호
- 선택항목: 전화번호, 주소

2. 개인정보의 수집 및 이용 목적
- 회원 관리: 본인 확인, 회원제 서비스 제공
- 서비스 제공: 맞춤형 콘텐츠 및 서비스 제공
- 통계 분석 및 서비스 개선

3. 개인정보의 보유 및 이용 기간
- 회원 탈퇴 시 즉시 파기
- 법령에 따른 보관이 필요한 경우, 관련 법령에 따름

4. 개인정보의 제3자 제공
- 원칙적으로 회원의 동의 없이 제3자에게 제공하지 않습니다.
- 다만, 법령에 따라 요청받은 경우 예외적으로 제공될 수 있습니다.

5. 개인정보 처리 위탁
- 서비스 운영을 위해 개인정보 처리 업무를 위탁할 수 있으며, 위탁 시 해당 사실을 회원에게 고지합니다.

6. 정보통신망법에 따른 고지
- 회원은 언제든지 자신의 개인정보에 대해 열람, 수정, 삭제를 요청할 수 있습니다.
- 개인정보의 유출이 발생한 경우, 즉시 회원에게 고지하고 필요한 조치를 취합니다.

본 동의서는 [개인정보 보호법] 및 [정보통신망 이용촉진 및 정보보호 등에 관한 법률]을 준수하여 작성되었습니다.
제공 동의: 수집된 개인정보는 서비스 제공 외 다른 용도로 사용되지 않습니다.
`}
                    ></textarea>

                    <div className="agreeBox">
                        <label htmlFor="agreement">
                            <input
                                type="checkbox"
                                id="agreement"
                                checked={isAgreed}
                                onChange={() => setIsAgreed(!isAgreed)} // 동의 상태 변경
                            />
                            <nbsp /> 저는 해당사항에 동의합니다.
                        </label>
                    </div>
                    <div className="emailBox">
                        <label>Email</label>
                        <input
                            className="textBox"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="이메일을 입력하세요"
                            required
                        />
                    </div>
                    <button type="submit">확인</button>
                    <div className="emailListBox">
                        <span>이메일 허용 목록에 존재하는 이메일만 사용 가능합니다.</span>
                        <li>naver.com</li>
                        <li>daum.net</li>
                        <li>gmail.com</li>
                    </div>
                    {error && <p style={{ color: 'red', marginTop: '10px' }}>{error}</p>}
                </form>
            )}

            {step === 2 && (
                <form onSubmit={handleRegisterSubmit}>
                    <h2>회원가입</h2>
                    <div>
                        <label>이메일:</label>
                        <input className="textBox" type="text" value={email} readOnly />
                    </div>
                    <div>
                        <label>사용자 이름:</label>
                        <input
                            className="textBox"
                            type="text"
                            name="name"
                            value={formData.name}
                            readOnly
                        />
                    </div>
                    <div>
                        <label>비밀번호:</label>
                        <input
                            className="textBox"
                            type="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            placeholder="비밀번호를 입력하세요"
                            required
                        />
                    </div>
                    <div>
                        <label>비밀번호 확인:</label>
                        <input
                            className="textBox"
                            type="password"
                            name="confirmPassword"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                            placeholder="비밀번호를 다시 입력하세요"
                            required
                        />
                    </div>
                    {error && <p style={{ color: 'red', marginTop: '10px' }}>{error}</p>}
                    <button type="submit">가입하기</button>
                </form>
            )}
        </div>
    );
};

export default Register;
