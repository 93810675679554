import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import './Login.css';

const Login = ({ setUser }) => {
  const [email, setEmail] = useState(''); // 이메일 상태
  const [password, setPassword] = useState(''); // 비밀번호 상태
  const [error, setError] = useState(''); // 에러 메시지 상태
  const [isSubmitting, setIsSubmitting] = useState(false); // 요청 진행 상태
  const [isAutoLogin, setIsAutoLogin] = useState(false); // 자동 로그인 상태
  const [cookies, setCookie, removeCookie] = useCookies(['userToken']); // 쿠키 관리

  const API_URL = import.meta.env.VITE_API_URL; // 환경 변수에서 API URL 가져오기
  const navigate = useNavigate(); // 페이지 이동을 위한 useNavigate

  // 새로고침 시 로컬 스토리지 또는 쿠키에서 사용자 정보 복원
  useEffect(() => {
    const savedUser = localStorage.getItem('user');
    const token = cookies.userToken;

    if (savedUser) {
      setUser(JSON.parse(savedUser)); // 로컬 스토리지에 저장된 사용자 정보 설정
    } else if (token) {
      axios
        .get(`${API_URL}/users/profile`, {
          credentials: 'include',
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const userData = response.data.user;
          setUser(userData);
          localStorage.setItem('user', JSON.stringify(userData)); // 로컬 스토리지에 저장
        })
        .catch(() => {
          removeCookie('userToken', { path: '/' });
        });
    }
  }, [API_URL, cookies.userToken, removeCookie, setUser]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email.trim() || !password.trim()) {
      setError('이메일과 비밀번호를 입력해주세요.');
      return;
    }

    setIsSubmitting(true);
    setError(''); // 기존 에러 메시지 초기화

    try {
      // 로그인 API 요청
      const response = await axios.post(`${API_URL}/users/login`, { email, password });

      if (response.data && response.data.user) {
        const userData = response.data.user; // 사용자 정보
        setUser(userData); // 상태 업데이트
        localStorage.setItem('user', JSON.stringify(userData)); // 로컬 스토리지에 사용자 정보 저장

        // 쿠키에 토큰 저장 (자동 로그인 선택 시)
        if (isAutoLogin) {
          setCookie('userToken', response.data.token, {
            path: '/',
            expires: new Date(Date.now() + 1 * 24 * 60 * 60 * 1000), // 1일 후 만료
            sameSite: 'strict', // 보안 강화
            secure: true, // HTTPS 환경에서는 true로 설정
          });
        } else {
          removeCookie('userToken', { path: '/' });
        }

        alert('로그인 성공!');
        navigate('/'); // 로그인 후 홈으로 이동
      } else {
        setError('로그인에 실패했습니다.');
      }
    } catch (err) {
      setError(err.response?.data?.message || '서버와 연결할 수 없습니다. 다시 시도해주세요.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="loginForm">
      <div className="xkxcjwnzsd">
        <h1>로그인</h1>
        <form onSubmit={handleSubmit}>
          <div className="inputDiv">
            <label>Email</label>
            <input
              type="text"
              name="UserName"
              placeholder="이메일을 입력하세요"
              value={email}
              onChange={(e) => setEmail(e.target.value)} // 이메일 상태 업데이트
              required
            />
          </div>
          <div className="inputDiv">
            <label>Password</label>
            <input
              type="password"
              name="Pwd"
              placeholder="비밀번호를 입력하세요"
              value={password}
              onChange={(e) => setPassword(e.target.value)} // 비밀번호 상태 업데이트
              required
            />
            <a href="#" onClick={(e) => e.preventDefault()}>
              비밀번호를 잃어버리셨나요?
            </a>
            {/* <div>
              <input
                type="checkbox"
                checked={isAutoLogin}
                onChange={(e) => setIsAutoLogin(e.target.checked)}
              />{' '}
              자동로그인
            </div> */}
          </div>
          <div className="btnDiv">
            <Link to="/Register">
              <button className="btnRegister" type="button">
                회원가입
              </button>
            </Link>
            <button className="btnLogin" type="submit" disabled={isSubmitting}>
              {isSubmitting ? '로그인 중...' : '로그인'}
            </button>
          </div>
        </form>
      </div>
      {error && <p style={{ color: 'red' }}>{error}</p>} {/* 에러 메시지 출력 */}
    </div>
  );
};

export default Login;
