import React, { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import './searchResults.css';

const SearchResults = () => {
  const [searchParams] = useSearchParams();
  const query = searchParams.get('q') || ''; // URL에서 검색어 가져오기
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const API_URL = import.meta.env.VITE_API_URL;

  useEffect(() => {
    if (query.trim() === '') {
      setIsLoading(false);
      return; // 검색어가 비어 있으면 API 호출 안 함
    }

    // 디버깅: 검색어 확인
    console.log('검색어:', query);

    // 검색 결과 가져오기
    const fetchSearchResults = async () => {
      try {
        const response = await fetch(
          `${API_URL}/content/search?q=${encodeURIComponent(query)}`,
          {
            method: 'GET',
            credentials: 'include',
          }
        );

        // 디버깅: API 응답 상태 확인
        console.log('API 응답 상태:', response.status);

        if (!response.ok) {
          // 에러 응답의 경우 텍스트로 읽기
          const errorText = await response.text();
          console.error('에러 응답 본문:', errorText);
          throw new Error('검색 결과를 가져오는데 실패했습니다.');
        }

        const data = await response.json();

        

        setResults(data);
      } catch (error) {
        setError('검색 결과를 가져오는 중 오류가 발생했습니다.');
        console.error('에러 내용:', error);
      } finally {
        setIsLoading(false);
      }
      // 디버깅: 결과 데이터 확인
      console.log('검색 결과:', data);
    };

    fetchSearchResults();
  }, [query, API_URL]);

  if (isLoading) {
    return <div>로딩 중...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="search-results">
      <h2>검색 결과</h2>
      {results.length > 0 ? (
        <div className="results-list">
          {results.map((item) => (
            <div key={item.id} className="result-item">
              <h3>{item.title}</h3>
              <p>카테고리: {item.category}</p>
              <div>{item.content}</div>
            </div>
          ))}
        </div>
      ) : (
        <div>
          <p>검색 결과가 없습니다.</p>
          <Link to={`/createContent?q=${encodeURIComponent(query)}`}>
            새 문서 만들기
          </Link>
        </div>
      )}
    </div>
  );
};

export default SearchResults;
