import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Discuss.css';

const Discuss = ({ isSidebar, user }) => {
  const [discussions, setDiscussions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const API_URL = import.meta.env.VITE_API_URL; // 환경 변수에서 API URL 가져오기

  console.log('API_URL:', API_URL); // 추가된 로그

  const handleRowClick = (discussionId) => {
    navigate(`/discuss/${discussionId}`);
  };

  // 토론 데이터 삭제 (관리자 전용)
  const handleDelete = (discussionId) => {
    if (!window.confirm('정말로 이 토론을 삭제하시겠습니까?')) return;

    fetch(`${API_URL}/api/discuss/${discussionId}`, {
      method: 'DELETE',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('토론 삭제에 실패했습니다.');
        }
        return response.json();
      })
      .then(() => {
        setDiscussions((prevDiscussions) =>
          prevDiscussions.filter((discussion) => discussion.id !== discussionId)
        );
        alert('토론이 성공적으로 삭제되었습니다.');
      })
      .catch((error) => {
        console.error('토론 삭제 중 오류:', error);
        setError('토론 삭제 중 오류가 발생했습니다.');
      });
  };

  // 토론 데이터 가져오기
  useEffect(() => {
    fetch(`${API_URL}/api/discuss`, {
      method: 'GET', // 조회수를 증가시키는 API 호출
      credentials: 'include',
    })
      .then((response) => {
        console.log('응답 상태:', response.status); // 추가된 로그
        if (!response.ok) {
          throw new Error('데이터 가져오기에 실패했습니다.');
        }
        return response.json();
      })
      .then((data) => {
        console.log('토론 데이터:', data); // 추가된 로그
        const filteredData = isSidebar ? data.slice(-5).reverse() : data;
        setDiscussions(filteredData);
        setIsLoading(false);
      })
      .catch((error) => {
        setError('토론 데이터를 가져오는 중 오류가 발생했습니다.');
        console.error('Error fetching discussions:', error);
        setIsLoading(false);
      });
  }, [API_URL, isSidebar]);

  return (
    <div
      className={`discuss-container ${isSidebar ? 'sidebar-view' : ''} ${
        user?.isAdmin ? 'admin-view' : 'user-view'
      }`}
    >
      {!isSidebar && (
        <div className="discuss-header">
          <h1>최근 토론</h1>
          <Link to="/create">
            <button className="create-button">글작성</button>
          </Link>
        </div>
      )}
      <div className="discuss-table">
        {isLoading ? (
          <p className="loading">로딩 중...</p>
        ) : error ? (
          <p className="error-message">{error}</p>
        ) : (
          <table>
            <thead>
              <tr>
                <th>제목</th>
                {!isSidebar && <th>내용</th>}
                <th>작성자</th>
                <th>작성일</th>
                {user?.isAdmin ? <th>삭제</th> : false}
              </tr>
            </thead>
            <tbody>
              {discussions.length > 0 ? (
                discussions.map((discussion) => (
                  <tr
                    key={discussion.id}
                    className="clickable-row"
                    onClick={(e) => {
                      if (e.target.tagName !== 'BUTTON' && e.target.tagName !== 'A') {
                        handleRowClick(discussion.id);
                      }
                    }}
                  >
                    <td>{discussion.title}</td>
                    {!isSidebar && <td>{discussion.content}</td>}
                    <td>{discussion.author}</td>
                    <td>
                      {new Date(discussion.created_at).toLocaleString('ko-KR', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                      })}
                    </td>
                    {user?.isAdmin ? (
                      <td>
                        <a
                          href="#"
                          className="tdDeleteBtn"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleDelete(discussion.id);
                          }}
                        >
                          삭제
                        </a>
                      </td>
                    ) : false}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={!isSidebar ? '4' : '3'} className="no-data">
                    현재 진행중인 토론이 없습니다.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default Discuss;
