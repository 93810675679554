import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './CreateDiscussion.css';

const CreateDiscussion = () => {
  const [newDiscussion, setNewDiscussion] = useState({
    title: '',
    author: '',
    content: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const API_URL = import.meta.env.VITE_API_URL; // 환경 변수에서 API URL 가져오기

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewDiscussion((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // 유효성 검사
    if (!newDiscussion.title || !newDiscussion.author || !newDiscussion.content) {
      setErrorMessage('모든 필드를 입력해주세요.');
      return;
    }

    if (newDiscussion.title.length > 100) {
      setErrorMessage('제목은 100자 이내로 입력해주세요.');
      return;
    }

    setIsSubmitting(true);
    setErrorMessage('');

    // API 요청
    fetch(`${API_URL}/api/discuss`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newDiscussion),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('토론 작성에 실패했습니다.');
        }
        return response.json();
      })
      .then(() => {
        navigate('/discuss'); // 작성 후 토론 목록 페이지로 이동
      })
      .catch((error) => {
        setErrorMessage('서버 오류가 발생했습니다. 다시 시도해주세요.');
        console.error('Error posting discussion:', error);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div className="create-discussion-container">
      <h1>새로운 토론 작성</h1>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <form onSubmit={handleSubmit} className="discussion-form">
        <div className="form-group">
          <label>제목</label>
          <input
            type="text"
            name="title"
            value={newDiscussion.title}
            onChange={handleChange}
            maxLength="100"
            required
          />
        </div>
        <div className="form-group">
          <label>작성자</label>
          <input
            type="text"
            name="author"
            value={newDiscussion.author}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>내용</label>
          <textarea
            name="content"
            value={newDiscussion.content}
            onChange={handleChange}
            required
          />
        </div>
        <div className="button-group">
          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? '작성 중...' : '작성완료'}
          </button>
          <button
            type="button"
            onClick={() => navigate('/discuss')}
            disabled={isSubmitting}
          >
            취소
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateDiscussion;
