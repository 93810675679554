import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Chart.css';

const Chart = () => {
  const [offset, setOffset] = useState(0); // 현재 아이템의 인덱스
  const [items, setItems] = useState([]); // API로부터 가져온 아이템 목록
  const [isHovered, setIsHovered] = useState(false); // hover 상태 관리

  const API_URL = import.meta.env.VITE_API_URL; // 환경 변수에서 API URL 가져오기

  // API에서 토론 목록 가져오기
  useEffect(() => {
    const fetchItems = () => {
      fetch(`${API_URL}/api/discuss`, {
        credentials: 'include' // 쿠키 또는 자격 증명을 포함하도록 설정
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          // 조회수가 높은 순서대로 정렬하고 최대 10개 항목만 가져오기
          const sortedItems = data.sort((a, b) => b.viewCount - a.viewCount).slice(0, 10);
          setItems(sortedItems);
        })
        .catch((error) => {
          console.error('Error fetching discussions:', error);
        });
    };

    fetchItems();

    // 5초마다 데이터를 갱신
    const interval = setInterval(fetchItems, 1000);
    return () => clearInterval(interval); // cleanup
  }, [API_URL]);

  // 3초마다 다음 아이템으로 이동 (hover 중일 때 멈춤)
  useEffect(() => {
    if (!isHovered && items.length > 0) {
      const interval = setInterval(() => {
        setOffset((prevOffset) => (prevOffset + 1) % items.length);
      }, 3000);

      return () => clearInterval(interval); // cleanup
    }
  }, [isHovered, items.length]);

  const translateY = -offset * 2; // 각 아이템의 높이가 2em

  // hover 상태 관리
  const handleMouseEnter = () => {
    setIsHovered(true);
    setOffset(0); // hover 상태에서 첫 번째 아이템부터 시작
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  // 조회수 증가 함수
  const handleItemClick = (id) => {
    fetch(`${API_URL}/api/discuss/${id}`, {
      method: 'GET', // 조회수를 증가시키는 API 호출
      credentials: 'include' // 쿠키 또는 자격 증명을 포함하도록 설정
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((updatedItem) => {
        // 성공적으로 조회수를 업데이트하면 로컬 상태도 업데이트
        setItems((prevItems) =>
          prevItems
            .map((item) =>
              item.id === updatedItem.id ? updatedItem : item
            )
            .sort((a, b) => b.viewCount - a.viewCount) // 다시 정렬
        );
      })
      .catch((error) => {
        console.error('Error updating view count:', error);
      });
  };

  return (
    <div
      className="chart-area"
      onMouseEnter={handleMouseEnter} // hover 시작
      onMouseLeave={handleMouseLeave} // hover 종료
    >
      <div className="chart-list">
        <ol style={{ transform: `translateY(${translateY}em)` }}>
          {items.map((item, index) => (
            <li className="item-list" key={item.id}>
              <div className="item-number">{index + 1}</div> {/* 순서 표시 */}
              <div className="item-name">
                <Link
                  to={`/discuss/${item.id}`}
                  onClick={() => handleItemClick(item.id)}
                >
                  {item.title}
                </Link>
              </div>
              <div className="item-viewCount">
                <span>(조회수: {item.viewCount})</span>
              </div>
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};

export default Chart;
