import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './DiscussionDetail.css';

const DiscussionDetail = ({ user }) => {
  const { id } = useParams();
  const [discussion, setDiscussion] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [authorName, setAuthorName] = useState('');
  const [nextIndex, setNextIndex] = useState(1);
  const [isLoadingDiscussion, setIsLoadingDiscussion] = useState(true);
  const [isLoadingComments, setIsLoadingComments] = useState(true);
  const [error, setError] = useState(null);

  const API_URL = import.meta.env.VITE_API_URL; // 환경 변수에서 API URL 가져오기

  // 토론 상세 정보를 가져오기
  useEffect(() => {
    // 상세 정보
    fetch(`${API_URL}/api/discuss/${id}`, {
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) throw new Error('토론 데이터를 가져오는데 실패했습니다.');
        return response.json();
      })
      .then((data) => {
        setDiscussion(data);
        setIsLoadingDiscussion(false);
      })
      .catch((error) => {
        setError('토론 데이터를 불러오는 중 오류가 발생했습니다.');
        setIsLoadingDiscussion(false);
        console.error(error);
      });

    // 댓글 목록
    fetch(`${API_URL}/api/discuss/${id}/comments`, {
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) throw new Error('댓글 데이터를 가져오는데 실패했습니다.');
        return response.json();
      })
      .then((data) => {
        setComments(data);
        const maxIndex = data.reduce((max, comment) => {
          const currentIndex = parseInt(comment.index.substring(1), 10);
          return currentIndex > max ? currentIndex : max;
        }, 0);
        setNextIndex(maxIndex + 1);
        setIsLoadingComments(false);
      })
      .catch((error) => {
        setError('댓글 데이터를 불러오는 중 오류가 발생했습니다.');
        setIsLoadingComments(false);
        console.error(error);
      });
  }, [id, API_URL]);

  const handleCommentSubmit = (e) => {
    e.preventDefault();
  
    if (!authorName.trim() || !newComment.trim()) {
      alert('작성자 이름과 댓글 내용을 모두 입력해주세요!');
      return;
    }
  
    const commentIndex = `#${nextIndex}`;
    const createdAt = new Date().toISOString();
  
    console.log(newComment, authorName, commentIndex, createdAt);
  
    fetch(`${API_URL}/api/discuss/${id}/comments`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        content: newComment,
        author: authorName,
        index: commentIndex,
        created_at: createdAt,
      }),
    })
      .then((response) => {
        if (!response.ok) throw new Error('댓글 작성에 실패했습니다.');
        return response.json();
      })
      .then((data) => {
        setComments([...comments, data]); // 새 댓글 추가
        setNewComment(''); // 입력 필드 초기화
        setNextIndex(nextIndex + 1); // 인덱스 증가
      })
      .catch((error) => {
        setError('댓글 작성 중 오류가 발생했습니다.');
        console.error(error);
      });
  };  
  

  // 댓글 삭제 함수
  const handleDeleteComment = (commentId) => {
    if (!window.confirm('정말로 이 댓글을 삭제하시겠습니까?')) return;

    fetch(`${API_URL}/api/discuss/${id}/comments/${commentId}`, {
      method: 'PUT',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) throw new Error('댓글 삭제에 실패했습니다.');
        return response.json();
      })
      .then(() => {
        setComments((prevComments) =>
          prevComments.map((comment) =>
            comment.id === commentId
              ? { ...comment, content: '관리자에 의해 삭제된 댓글입니다.' }
              : comment
          )
        );
      })
      .catch((error) => {
        setError('댓글 삭제 처리 중 오류가 발생했습니다.');
        console.error(error);
      });
  };

  if (isLoadingDiscussion) return <div>로딩 중... 토론 데이터를 가져오고 있습니다.</div>;
  if (error) return <div>{error}</div>;

  // 토론 상세 내역 출력
  return (
    <div className="discussion-detail">
      <div className="discussion-content">
        <h1>{discussion.title}</h1>
        <div className="meta-info">
          <span>작성자: {discussion.author}</span>
          <span>작성일: {new Date(discussion.created_at).toLocaleString('ko-KR')}</span>
          <span>조회수 : {discussion.viewCount}</span>
        </div>
        <div className="content">{discussion.content}</div>
      </div>

      <div className="comments-section">
        <h2>댓글</h2>
        <form onSubmit={handleCommentSubmit} className="comment-form">
          <div className="author-input">
            <input
              type="text"
              value={authorName}
              onChange={(e) => setAuthorName(e.target.value)}
              placeholder="작성자 이름"
              required
            />
          </div>
          <textarea
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="댓글을 작성해주세요!"
            required
          />
          <button type="submit">댓글 작성</button>
        </form>

        {isLoadingComments ? (
          <p>댓글 데이터를 로드 중입니다...</p>
        ) : comments.length > 0 ? (
          <div className="comments-list">
            {comments.map((comment) => (
              <div key={comment.id} className="comment">
                <div className="comment-header">
                  <span className="comment-index">{comment.index}</span>
                  <span className="comment-author">
                    {comment.author}
                    {user?.isAdmin && comment.content !== '관리자에 의해 삭제된 댓글입니다.' && (
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleDeleteComment(comment.id);
                        }}
                        className="comment-delete"
                      >
                        삭제
                      </a>
                    )}
                  </span>
                  <span className="comment-date">
                  {new Date(comment.created_at).toLocaleString('ko-KR', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                  })}
                  </span>
                </div>
                <div className="comment-content">{comment.content}</div>
              </div>
            ))}
          </div>
        ) : (
          <p>현재 댓글이 없습니다. 첫 번째 댓글을 작성해보세요!</p>
        )}
      </div>
    </div>
  );
};

export default DiscussionDetail;
