import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify'; // HTML 파싱 보안
import './CreateContent.css'; // 기존 Body 스타일 재사용

const CreateContent = ({ user }) => {
  const navigate = useNavigate();
  const [newContent, setNewContent] = useState({
    title: '',
    category: '',
    content: '',
    advertisement: '', // 광고 이미지 URL
  });

  const [userIP, setUserIP] = useState(''); // 사용자 IP 상태 추가
  const [isSubmitting, setIsSubmitting] = useState(false); // 제출 상태
  const [error, setError] = useState(null);
  const [isChecked, setIsChecked] = useState(false); // 비회원 체크박스 상태
  const API_URL = import.meta.env.VITE_API_URL;

  // 사용자 IP 가져오기
  useEffect(() => {
    const fetchIP = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        setUserIP(data.ip);
      } catch (error) {
        console.error('IP 가져오기 오류:', error);
        setUserIP('IP를 가져올 수 없습니다.');
      }
    };

    fetchIP();
  }, []);

  // 입력값 처리 함수
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewContent({ ...newContent, [name]: value });
  };

  // 체크박스 상태 업데이트
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  // 콘텐츠 제출 함수
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!user && !isChecked) {
      alert('비회원으로 작성 시, 본인의 아이피가 영구적으로 남을 수 있습니다. 체크박스를 선택해 주세요.');
      return;
    }

    setIsSubmitting(true);

    const contentData = {
      ...newContent,
      editor: user ? user.name : `비회원(${userIP})`,
    };

    fetch(`${API_URL}/api/createContent`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(contentData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        alert('콘텐츠가 성공적으로 작성되었습니다.');
        navigate(`/content/${data.id}`);
      })
      .catch((error) => {
        setError('콘텐츠 작성 중 오류가 발생했습니다.');
        console.error('Error:', error);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  // JSX 구조 유지
  return (
    <div className="mainContent">
      <div className="firstContent">
        <div className="znmqjwsnz">
          <label>
            <h2>
              제목:
              <input
                type="text"
                name="title"
                value={newContent.title}
                onChange={handleInputChange}
                required
              />
            </h2>
          </label>
          <div className="recentEdit">
            최근 수정 시각:
            <time>
              {new Date().toLocaleString('ko-KR', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
              })}
            </time>
          </div>
        </div>
      </div>

      <hr />

      <div className="secondContent">
        <label>
          이미지 URL :
          <input
            type="text"
            name="advertisement"
            value={newContent.advertisement}
            onChange={handleInputChange}
          />
        </label>
      </div>

      <hr />

      <div className="thirdContent">
        <label>
          카테고리 :
          <input
            type="text"
            name="category"
            value={newContent.category}
            onChange={handleInputChange}
            required
          />
        </label>
        <hr />
        <label>
          <textarea
            name="content"
            value={newContent.content}
            onChange={handleInputChange}
            rows="15"
            placeholder='사용자의 작성 내용은 대림위키에 저장됩니다.'
            required
          />
        </label>
      </div>

      <hr />

      <div className="fourthContent">
        {!user && (
          <div>
            <label htmlFor="agreement">
              <input
                type="checkbox"
                id="agreement"
                checked={isChecked}
                onChange={handleCheckboxChange}
              /> <nbsp />
              비회원 작성 시, 본인의 아이피가 영구적으로 남을 수 있습니다.
            </label>
          </div>
        )}
        <button type="submit" onClick={handleSubmit} disabled={isSubmitting}>
          {isSubmitting ? '제출 중...' : '제출'}
        </button>
        {error && <p className="error-message">{error}</p>}
      </div>
    </div>
  );
};

export default CreateContent;
