import React, { useState, useEffect } from 'react';
import './MyDocument.css';

import starEmpty from '../../assets/star-empty.png';
import starFilled from '../../assets/star-filled.png';
import recent_edited from '../../assets/recent_edited.png';
import discuss from '../../assets/discuss.png';
import locker from '../../assets/locker.png';

const MyDocument = ({ user }) => {
  const contentId = user?.myDocument; // 사용자 문서 ID
  const API_URL = import.meta.env.VITE_API_URL; // 환경 변수에서 API URL 가져오기

  const [content, setContent] = useState(null); // 콘텐츠 상태
  const [isEditing, setIsEditing] = useState(false); // 수정 모드 상태
  const [isStarred, setIsStarred] = useState(false); // 즐겨찾기 상태
  const [editedContent, setEditedContent] = useState({
    title: '',
    category: '',
    content: '',
    advertisement: '',
  });

  // 사용자 문서 가져오기
  useEffect(() => {
    if (!contentId) {
      console.error('사용자 문서 ID가 없습니다.');
      return;
    }

    fetch(`${API_URL}/api/content/${contentId}`, {
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setContent(data);
        setEditedContent({
          title: data.title,
          category: data.category,
          content: data.content,
          advertisement: data.advertisement,
        });

        if (user?.starredId?.includes(contentId)) {
          setIsStarred(true);
        }
      })
      .catch((error) => {
        console.error('문서 가져오기 오류:', error);
      });
  }, [API_URL, contentId, user]);

  // 문서 수정 후 저장
  const handleEditSave = () => {
    if (!user) {
      alert('로그인 후 저장이 가능합니다.');
      return;
    }

    const updatedContent = {
      ...editedContent,
      editor: user.name,
    };

    fetch(`${API_URL}/api/content/${contentId}`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedContent),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        alert('문서가 성공적으로 저장되었습니다.');
        setContent(updatedContent);
        setIsEditing(false);
      })
      .catch((error) => {
        console.error('저장 중 오류 발생:', error);
        alert('저장 중 오류가 발생했습니다.');
      });
  };

  // 즐겨찾기 처리
  const handleStarClick = () => {
    if (!user) {
      alert('로그인 후 즐겨찾기 가능합니다.');
      return;
    }

    const endpoint = isStarred
      ? `${API_URL}/api/content/${contentId}/unstar`
      : `${API_URL}/api/content/${contentId}/star`;

    fetch(endpoint, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId: user.id }),
    })
      .then((response) => response.json())
      .then(() => {
        setIsStarred(!isStarred);
      })
      .catch((error) => {
        console.error('즐겨찾기 처리 오류:', error);
      });
  };

  // HTML 태그 파싱
  const parseHTMLToJSX = (htmlString) => {
    return htmlString.split('\n').map((line, index) => {
      if (line.startsWith('<h1>')) {
        return <h1 key={index}>{line.replace(/<\/?h1>/g, '')}</h1>;
      } else if (line.startsWith('<li>')) {
        return <li key={index}>{line.replace(/<\/?li>/g, '')}</li>;
      } else if (line.startsWith('<hr>')) {
        return <hr key={index} />;
      } else if (line.startsWith('<p>')) {
        return <p key={index}>{line.replace(/<\/?p>/g, '')}</p>;
      } else {
        return (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        );
      }
    });
  };

  if (!content) {
    return <div>문서를 불러오는 중입니다...</div>;
  }

  return (
    <div className="mainContent">
      <div className="firstContent">
        <div className="znmqjwsnz">
          {isEditing ? (
            <label>
              <h2>
                제목:
                <input
                  type="text"
                  name="title"
                  value={editedContent.title}
                  onChange={(e) =>
                    setEditedContent({ ...editedContent, title: e.target.value })
                  }
                />
              </h2>
            </label>
          ) : (
            <h1>{content.title}</h1>
          )}

          <div className="recentEdit">
            최근 수정 시각:
            <time>
              {content.lastModified &&
                new Date(content.lastModified).toLocaleString('ko-KR', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                })}
            </time>
          </div>
        </div>
        <div className="buttonFunctions">
          <div>
            <span className="starredNum">{content?.starred}</span>
          </div>
          <li className="btnBox01">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleStarClick();
              }}
            >
              <img src={isStarred ? starFilled : starEmpty} alt="Star" />
            </a>
          </li>
          {user?.id ? (
            <li className="box02">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setIsEditing(true);
                }}
              >
                <img src={recent_edited} alt="Edit" />
                <span>편집</span>
              </a>
            </li>
          ) : (
            <li className="btnBox02">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  alert('편집 권한이 부족합니다!');
                }}
                className="lockedButton"
              >
                <img src={recent_edited} alt="Edit" />
                <span>편집</span>
                <img src={locker} alt="Locked" className="lockerIcon" />
              </a>
            </li>
          )}
          <li className="btnBox03">
            <a href="#discuss">
              <img src={discuss} alt="Discuss" />
              <span>토론</span>
            </a>
          </li>
        </div>
      </div>
      <hr />
      <div className="thirdContent">
        {isEditing ? (
          <div className="editForm">
            <label>
              카테고리:
              <input
                type="text"
                name="category"
                value={editedContent.category}
                onChange={(e) => setEditedContent({ ...editedContent, category: e.target.value })}
              />
            </label>
            <hr />
            <label>
              내용:
              <textarea
                name="content"
                value={editedContent.content}
                onChange={(e) => setEditedContent({ ...editedContent, content: e.target.value })}
                rows="15"
              />
            </label>
            <button onClick={handleEditSave}>저장하기</button>
            <button onClick={() => setIsEditing(false)}>취소</button>
          </div>
        ) : (
          <article>{content.content && parseHTMLToJSX(content.content)}</article>
        )}
      </div>
      <hr />
      <div className="fourthContent">
        <span>
          대림위키는 백과사전이 아니며 편향적이거나 잘못 서술되어 있을 수 있습니다.
          <br />
          대림위키는 응애위키입니다. 여러분이 직접 문서를 고칠 수 있으며, 다른 사람의 의견을
          원할 경우 직접 토론을 발제할 수 있습니다.
        </span>
      </div>
    </div>
  );
};

export default MyDocument;
