import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import './Navbar.css';

import daelim_logo from '../../assets/daelim_logo.png';
import search from '../../assets/search.png';
import user_circle from '../../assets/user-circle.png';
import discuss from '../../assets/discuss.png';
import recent_edited from '../../assets/recent_edited.png';

const Navbar = ({ user, setUser }) => {
  const navigate = useNavigate();
  const [, , removeCookie] = useCookies(['userToken']); // 쿠키 삭제를 위한 useCookies

  const [searchQuery, setSearchQuery] = useState(''); // 검색어 상태 추가
  const [userIP, setUserIP] = useState(''); // 사용자 IP 상태 추가

  const handleLogout = () => {
    setUser(null); // 사용자 상태 초기화
    localStorage.removeItem('user'); // 로컬 스토리지에서 사용자 정보 제거
    removeCookie('userToken', { path: '/' }); // 쿠키 삭제
    alert('로그아웃 되었습니다.');
    navigate('/'); // 홈으로 이동
  };

  // 검색 기능
  const handleSearch = (e) => {
    e.preventDefault();
    if (searchQuery.trim() === '') {
      alert('검색어를 입력해주세요.');
      return;
    }

    // 검색 결과 페이지로 이동하며 검색어를 전달
    navigate(`/search?q=${encodeURIComponent(searchQuery.trim())}`);
  };

  // 사용자 IP 가져오기
  useEffect(() => {
    const fetchIP = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        setUserIP(data.ip);
      } catch (error) {
        console.error('IP 가져오기 오류:', error);
        setUserIP('IP를 가져올 수 없습니다.');
      }
    };

    fetchIP();
  }, []);

  return (
    <div className="navbar">
      <nav>
        {/* 메인 네비게이션 */}
        <div className="mainNav">
          <ul>
            <li className="logoImg">
              <Link to="/">
                <img className="logo" src={daelim_logo} alt="logo" />
              </Link>
            </li>
            <li className="list-Link">
              <Link to="/discuss">
                <span>
                  <img className="navImg" src={discuss} alt="discuss" />
                  최근 토론
                </span>
              </Link>
            </li>
            <li className="list-Link">
              <Link to="/recChanges">
                <span>
                  <img className="navImg" src={recent_edited} alt="recent changes" />
                  최근 변경
                </span>
              </Link>
            </li>
          </ul>
        </div>

        {/* 서브 네비게이션 */}
        <div className="subNav">
          <ul className="subNavList">
            <li className="search-box">
              <input
                type="text"
                placeholder="여기에서 검색하세요."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <Link to="/searchResults" onClick={handleSearch}>
                <img src={search} alt="search" />
              </Link>
            </li>
            <li>
              <Link to="#">문의사항</Link>
            </li>
            <li className="dropdown">
              <button className="dropBtn">
                <img className="navImg" src={user_circle} alt="user" />
              </button>
              <div className="dropdown-content">
                {user ? (
                  <>
                    <span>사용자</span>
                    <span>{user.email}</span>
                    <hr />
                    <Link to="/myPage">내 정보</Link>
                    <Link to="/myDocument">내 사용자 문서</Link>
                    <hr />
                    <button onClick={handleLogout}>로그아웃</button>
                  </>
                ) : (
                  <>
                    <span>방문자</span>
                    <span>아이피: {userIP}</span>
                    <Link to="/login">로그인</Link>
                  </>
                )}
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
